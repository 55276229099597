import { jsx, jsxs } from 'react/jsx-runtime';
import { createContext, useReducer, useContext } from 'react';
import { v4 } from 'uuid';
import styled from 'styled-components';
import { Icon } from '@eir/ui';

var ActionKind;
(function (ActionKind) {
    ActionKind["AddNotification"] = "ADD_NOTIFICATION";
    ActionKind["RemoveNotification"] = "REMOVE_NOTIFICATION";
    ActionKind["UpdateNotification"] = "UPDATE_NOTIFICATION";
})(ActionKind || (ActionKind = {}));
const NotificationContext = createContext(null);
const notificationReducer = (state, action) => {
    switch (action.type) {
        case ActionKind.AddNotification:
            return Object.assign(Object.assign({}, state), { notifications: [...state.notifications, action.payload] });
        case ActionKind.RemoveNotification:
            return Object.assign(Object.assign({}, state), { notifications: [
                    ...state.notifications.filter(notification => notification.id !== action.payload),
                ] });
        case ActionKind.UpdateNotification:
            return Object.assign(Object.assign({}, state), { notifications: state.notifications.map(notification => notification.id === action.payload.id ? action.payload : notification) });
        default:
            return state;
    }
};
const NotificationProvider = ({ children }) => {
    const initialState = {
        notifications: [],
    };
    const [state, dispatch] = useReducer(notificationReducer, initialState);
    const addNotification = (message, type) => {
        const id = v4();
        dispatch({
            type: ActionKind.AddNotification,
            payload: { id, message, type },
        });
        return id;
    };
    const removeNotification = (id) => dispatch({ type: ActionKind.RemoveNotification, payload: id });
    const removeNotificationWithDelay = (id, delay = 5000) => {
        setTimeout(() => removeNotification(id), delay);
    };
    const updateNotification = (id, message, type) => dispatch({
        type: ActionKind.UpdateNotification,
        payload: { id, message, type },
    });
    return (jsx(NotificationContext.Provider, Object.assign({ value: {
            notifications: state.notifications,
            addNotification,
            removeNotification,
            removeNotificationWithDelay,
            updateNotification,
        } }, { children: children })));
};
const useNotification = () => useContext(NotificationContext);

const NotificationItem = ({ id, message, type }) => {
    const { removeNotification } = useNotification();
    const CheckIcon = jsx(Icon, { iconName: 'faCheckCircle' });
    const WarningIcon = jsx(Icon, { iconName: 'faExclamationCircle' });
    const CloseIcon = jsx(Icon, { iconName: 'faTimes' });
    return (jsxs(NotificationItemContainer, Object.assign({ type: type }, { children: [type === 'alert' && jsx(IconContainer, { children: WarningIcon }), type === 'success' && jsx(IconContainer, { children: CheckIcon }), jsx(MessageContainer, { children: message }), (type === 'alert' || type === 'success') && (jsx(CloseContainer, Object.assign({ onClick: () => removeNotification(id) }, { children: CloseIcon })))] })));
};
const NotificationItemContainer = styled.div `
  display: flex;
  align-items: center;
  color: ${props => props.type === 'alert'
    ? '#721c24'
    : props.type === 'success'
        ? '#3c763d'
        : 'inherit'};
  background-color: ${props => props.type === 'alert'
    ? '#f8d7da'
    : props.type === 'success'
        ? '#dff0d8'
        : '#fff'};
  border: ${props => props.type === 'alert'
    ? '#f5c6cb'
    : props.type === 'success'
        ? '1px solid #d6e9c6'
        : '1px solid #dedede'};
  margin: 0.5rem;
  width: 30rem;
`;
const IconContainer = styled.div `
  font-size: 1.6rem;
  margin-left: 1.5rem;
`;
const MessageContainer = styled.div `
  flex: 1;
  font-size: 1rem;
  padding: 1rem 0.5rem;
`;
const CloseContainer = styled.div `
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const Notifications = () => {
    const { notifications } = useNotification();
    return (jsx(NotificationsContainer, { children: notifications.map(notification => (jsx(NotificationItem, { id: notification.id, message: notification.message, type: notification.type }, notification.id))) }));
};
const NotificationsContainer = styled.div `
  display: flex;
  flex-flow: column-reverse;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
`;

export { NotificationProvider, Notifications, useNotification };
