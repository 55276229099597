import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useLocation } from 'react-router-dom';

const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

const dateDiff = (startDate, endDate, unit = 'days') => {
    const MILLISECONDS_IN_ONE_DAY = 1000 * 60 * 60 * 24;
    const difference = endDate.getTime() - startDate.getTime();
    return Math.floor(difference / MILLISECONDS_IN_ONE_DAY);
};

const formatDrivingDistance = (distance) => {
    if (distance.search(/\+/) > -1) {
        const distanceInterval = distance.split('+');
        return `${Number(distanceInterval[0]).toLocaleString('sv-SE')} +`;
    }
    else {
        const distanceInterval = distance.split('-');
        return `${Number(distanceInterval[0]).toLocaleString('sv-SE')} - ${Number(distanceInterval[1]).toLocaleString('sv-SE')}`;
    }
};

const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString('sv-SE', { dateStyle: 'short' });
};

const formatDateTime = (date) => {
    const d = new Date(date);
    return d.toLocaleString('sv-SE', { dateStyle: 'short', timeStyle: 'short' });
};

const formatDateTimeZone = (date) => {
    const d = new Date(new Date(date).toISOString().slice(0, -1));
    return d.toLocaleString('sv-SE', { dateStyle: 'short' });
};

const range = (start, end, step = 1) => {
    if (start === end)
        return [start];
    return Array(end - start + 1)
        .fill(0)
        .map((_, index) => start + index);
};

const formatSwedishPostcode = (number) => {
    return number
        .toString()
        .split(/(\d{3})/)
        .join(' ');
};

const useClickOutside = (ref, callback) => {
    const [executeCallback, setExecuteCallback] = useState(true);
    const handleClick = (e) => {
        if (executeCallback && ref.current && !ref.current.contains(e.target)) {
            callback(executeCallback);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [executeCallback]);
    return { setExecuteCallback };
};

const useFuse = (data, options) => {
    const [pattern, setPattern] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const fuse = new Fuse(data, options);
    useEffect(() => {
        setFilteredList(pattern.trim().length === 0
            ? [...data]
            : fuse.search(pattern).map(result => result.item));
    }, [data, pattern]);
    const handlePatternChange = (event) => setPattern(event.target.value);
    return { filteredList, pattern, handlePatternChange };
};

const useQueryParam = () => new URLSearchParams(useLocation().search);

export { addDays, dateDiff, formatDate, formatDateTime, formatDateTimeZone, formatDrivingDistance, formatSwedishPostcode, range, useClickOutside, useFuse, useQueryParam };
