import { jsx, jsxs } from 'react/jsx-runtime';
import styled from 'styled-components';
import { Icon, Button } from '@eir/ui';
import { createContext, useReducer, useContext } from 'react';

const OverlayBackground = ({ children }) => {
    return jsx(Background, { children: children });
};
const Background = styled.div `
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: saturate(100%) blur(4px);
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

var ActionKind;
(function (ActionKind) {
    ActionKind["ClearAlert"] = "CLEAR_ALERT";
    ActionKind["SetAlert"] = "SET_ALERT";
})(ActionKind || (ActionKind = {}));
const AlertContext = createContext(null);
const alertReducer = (state, action) => {
    switch (action.type) {
        case ActionKind.ClearAlert:
            return null;
        case ActionKind.SetAlert:
            return action ? action.payload : null;
        default:
            return state;
    }
};
const AlertProvider = ({ children }) => {
    const initialState = null;
    const [state, dispatch] = useReducer(alertReducer, initialState);
    const clearAlert = () => dispatch({ type: ActionKind.ClearAlert, payload: null });
    const setAlert = (alert) => {
        dispatch({ type: ActionKind.SetAlert, payload: alert });
    };
    return (jsx(AlertContext.Provider, Object.assign({ value: {
            alert: state,
            clearAlert,
            setAlert,
        } }, { children: children })));
};
const useAlert = () => useContext(AlertContext);

const Alert = () => {
    const { alert } = useAlert();
    const settings = {
        question: {
            iconName: 'faQuestion',
            backgroundColor: '#3a58de',
            buttonTheme: 'blue',
        },
        success: {
            iconName: 'faCheck',
            backgroundColor: '#42c67f',
            buttonTheme: 'green',
        },
        warning: {
            iconName: 'faExclamationTriangle',
            backgroundColor: '#f4736b',
            buttonTheme: 'red',
        },
    };
    return (alert && (jsx(OverlayBackground, { children: jsxs(AlertBox, { children: [jsx(Header, Object.assign({ color: settings[alert.type].backgroundColor }, { children: jsx(Icon, { iconName: settings[alert.type].iconName, color: '#fff', size: '3x' }) })), jsxs(Body, { children: [jsx("h2", { children: alert.body.header }), jsx("p", { children: alert.body.message })] }), jsx(ButtonsContainer, { children: Object.entries(alert.buttons).map(([key, value]) => (jsx(Button, Object.assign({ variant: value.type, theme: settings[alert.type].buttonTheme, onClick: value.action }, { children: value.text }), key))) })] }) })));
};
const AlertBox = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 40rem;
  box-shadow: 0 0.125rem 0.62rem rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  border-radius: 0.5rem;
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding: 1.5rem;
  color: #fff;
  background-color: ${({ color }) => color};
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;
const Body = styled.div `
  padding: 1.2rem;
  margin-bottom: 2.4rem;
`;
const ButtonsContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  padding: 1.2rem;

  button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export { Alert, AlertProvider, useAlert };
